<script setup>
import { JHeaderMenuV2 } from 'media-flow-ui'

import { formatMenuStyle } from '@/helpers/header/menu'
import { formatIconStyle } from '~/helpers/icon/icon'
import { useAuthBuilder } from '~/stores/auth-builder'
import { useUrlBuilder } from '~/stores/url-builder'
import { storeToRefs } from 'pinia'

const authBuilderStore = useAuthBuilder()
const urlBuilder = useUrlBuilder()
const { userToken } = storeToRefs(authBuilderStore)
const { getProjectId } = storeToRefs(urlBuilder)

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  onToggle: {
    type: Function,
    default: () => {},
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
  logoData: {
    type: [Object, null],
    default: null,
  },
  isNested: {
    type: Boolean,
    default: false,
  },
})

const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatMenuStyle(
      { ...props.data.style, ...(props.isNested ? { width: '100%' } : {}) },
      props.data.props.layout
    ),
  }
})

const LOGO_DATA = computed(() => {
  if (props.logoData) {
    return {
      ...props.logoData.props,
      source: props.logoData.props.secondSource,
      customWrapper:
        props.logoData.props.linkType === 1 ? 'a' : defineNuxtLink({}),
    }
  }
})

watch(userToken, (val) => {
  if (val && getProjectId.value === 'rWbMwrOxNq2') {
    props.data?.extras.push({
      id: 23142122411241224114224,
      url: '/welcome-back',
      order: 0,
      itemId: '231421fsajihfdsafsoa',
      itemType: 'Page',
      childItems: [],
      itemTypeId: 1,
      iconSettings: null,
      itemLinkType: null,
      itemLinkTypeId: null,
      navigationLabel: 'My Videos',
    })
  }
})

const iconsStyle = formatIconStyle(props.data.icon)
</script>

<template>
  <!-- <pre>{{ iconsStyle.style.color }}</pre> -->

  <JHeaderMenuV2
    :is-shown="isOpen"
    :is-open="isOpen"
    :on-toggle="onToggle"
    :on-menu-close="onClose"
    :breakpoint="breakpoint"
    :data="formattedData"
    :icons="iconsStyle"
    :menu-items="data.extras || formattedData.props.items || []"
    :logo="LOGO_DATA"
    fixed-wrapper-class="fixed"
    :is-nested-component="isNested"
    :class="'order-' + formattedData.order"
  />
</template>

<style></style>
