import Script from "./Script"

class CustomScript {
  constructor(isCLH) {
    this.scripts = []
    this.isCLH = isCLH
  }

  getServerSideScripts(extraUrl) {
    if (this.isCLH) return []
    const analyticsHandlerWithBisko = {
      src: extraUrl,
      tagPosition: "head",
      tagPriority: -2,
      class: "load-internal-script",
      isSSR: true,
    }

    const SSRScripts = this.scripts.filter((script) => script.isSSR)

    if (Array.isArray(SSRScripts)) {
      SSRScripts.unshift(analyticsHandlerWithBisko)
    }

    return SSRScripts
  }

  getCustomScriptsToLoadEachPage() {
    if (this.isCLH) return []

    return this.scripts
      .filter((s) => !s.isSSR && s.loadOption === "onceineachpage")
      .map((script) => {
        script.loadedTime = new Date().getTime()
        return script
      })
  }

  getCustomScriptsToLoadOnce() {
    if (this.isCLH) return []

    return this.scripts.filter((s) => !s.isSSR && s.loadOption === "once")
  }

  fromData(data) {
    if (!data) {
      this.scripts = []
      return
    }

    const scripts = data.map((rawScript) => {
      const script = new Script()
      script.fromData(rawScript)
      return script
    })

    this.scripts = scripts
  }

  //   // const customScripts = state.customScripts
  //   const urlBuilderStore = useUrlBuilder();

  //   const { isCLH, config } = storeToRefs(urlBuilderStore);

  //   if (isCLH.value) return []

  //   const analyticsHandlerWithBisko = {
  //     src: config.value.public.analyticsUrl,
  //     tagPosition: "head",
  //     tagPriority: -2,
  //     class: 'load-internal-script',
  //     isSSR: true,
  //   }

  //   // state.layoutData?.scripts.

  //   const SSRScripts = state.layoutData?.scripts.filter(s => s.isSSR)

  //   if (Array.isArray(SSRScripts)) {
  //     SSRScripts.unshift(analyticsHandlerWithBisko)
  //   }

  //   return SSRScripts
}

export default CustomScript
