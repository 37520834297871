<script setup>
import { useAuthBuilder } from '~/stores/auth-builder'
import { usePageBuilder } from '~/stores/page-builder'
import { useSignalrBuilder } from './stores/signalr-builder'
import { useGlobalBuilder } from './stores/global-builder'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { changeLanguage } from '~~/utils/i19m'
import { useUrlBuilder } from '~/stores/url-builder'
import { PAGE_TYPES } from '@/constants/pageTypes'
import { emitter } from '~/utils/emitter'
if (process.server) {
  useServer()
}

const route = useRoute()

const pageBuilderStore = usePageBuilder()
const authBuilderStore = useAuthBuilder()
const signalrBuilderStore = useSignalrBuilder()
const globalBuilderStore = useGlobalBuilder()
const urlBuilder = useUrlBuilder()
const { getProjectId } = storeToRefs(urlBuilder)

const config = useRuntimeConfig()
const { isMobile, isTablet, isDesktop } = useDevice()
pageBuilderStore.setDeviceType(isMobile, isTablet, isDesktop)
pageBuilderStore.SET_CONFIG(config)

const { global, getPalette, getIconPack, getFontPack } =
  storeToRefs(pageBuilderStore)

const { isRedirectingToLogin } = storeToRefs(globalBuilderStore)
const { user, userSubId } = storeToRefs(authBuilderStore)

const headerData = useState('header-shallow-state', () =>
  shallowRef({ deep: 'not reactive' })
)
const footerData = useState('footer-shallow-state', () =>
  shallowRef({ deep: 'not reactive' })
)
const scriptData = useState('script-shallow-state', () =>
  shallowRef({ deep: 'not reactive' })
)
const ssrPageData = ref(null)
const generalStyle = ref(null)
const showHeaderFooter = ref(true)

const labelLocale = ref('en')
const isEmbed = ref(false)

if (route.path.includes('/embed')) {
  isEmbed.value = true
}

const managePageStyle = (data) => {
  const { omitHeaderFooter } = data ?? {}

  if (omitHeaderFooter && omitHeaderFooter === true) {
    showHeaderFooter.value = false
  } else {
    showHeaderFooter.value = true
  }
}

await useAsyncData(async () => {
  if (process.server) {
    const event = useRequestEvent()
    try {
      const { header, footer, scriptsSSR, page, rootStylea } =
        await pageBuilderStore.actionGetPageInit(route.path)

      headerData.value = header
      footerData.value = footer

      if (getProjectId.value && getProjectId.value === 'rWbMwrOxNq2') {
        if (scriptsSSR && scriptsSSR.length > 0) {
          scriptsSSR[0].src =
            'https://cdn.vpapps.cloud/scripts/mondans-analytics.js'
        }
      }

      // console.error('page', page)
      const isSearchPage = route.path.includes('/results')
      const isCheckoutPage = route.path.includes('/checkout')

      if (page) {
        if (
          PAGE_TYPES.NOT_FOUND_PAGE === page.typeId &&
          !isSearchPage &&
          !isCheckoutPage
        ) {
          setResponseStatus(event, 404)
        }
      }
      scriptData.value = scriptsSSR
      ssrPageData.value = page
      generalStyle.value = rootStyle

      managePageStyle(generalStyle.value)
    } catch (error) {
      console.error('PAGE INIT: ', error)
      // setResponseStatus(event, 404)
    }
  }
  // return { header, footer, scriptsSSR, page, rootStyle }
})

onServerPrefetch(() => {
  const links = []

  // useHead({
  //   script: [
  //     {
  //       defer: true,
  //       src:
  //         getProjectId.value === 'rWbMwrOxNq2'
  //           ? 'https://host.vpplayer.tech/player/v2.1.0/vpplayer.js'
  //           : 'https://host.vpplayer.tech/player/v2.0.9/vpplayer.js',
  //     },
  //   ],
  // })

  useHead({
    script: [
      {
        async: true,
        src:
          getProjectId.value === 'rWbMwrOxNq2'
            ? 'https://bisko.gjirafa.net/web/dance_sport_life-sdk.js'
            : 'https://bisko.gjirafa.net/web/vpapps-sdk.js',
      },
    ],
  })

  if (getPalette.value) {
    const paletteObj = {
      href: getPalette.value,
      rel: 'stylesheet',
      type: 'text/css',
    }
    const paletteObjPreload = {
      href: getPalette.value,
      rel: 'preload',
      type: 'text/css',
      as: 'style',
    }

    links.push(paletteObjPreload)
    links.push(paletteObj)
  }

  if (getFontPack.value) {
    const fontObj = {
      href: getFontPack.value,
      rel: 'stylesheet',
      type: 'text/css',
    }
    const fontObjPreload = {
      href: getFontPack.value,
      rel: 'preload',
      type: 'text/css',
      as: 'style',
    }

    links.push(fontObjPreload)
    links.push(fontObj)
  }

  const fontObj = {
    href: getIconPack.value,
    rel: 'stylesheet',
    type: 'text/css',
  }

  const fontObjPreload = {
    href: getIconPack.value,
    rel: 'preload',
    type: 'text/css',
    as: 'style',
  }

  links.push(fontObjPreload)
  links.push(fontObj)

  // console.error('app.vue onServerPrefetch--->', scriptData.value)
  useHead({
    htmlAttrs: {
      lang: global.value.language?.isoLocale || 'en',
    },
    script: scriptData.value,
    link: links,
  })
})

labelLocale.value = global.value.language?.locale || 'en'
changeLanguage(labelLocale.value)

onMounted(async () => {
  await authBuilderStore.setUserManager()

  try {
    await authBuilderStore.getUser()
  } catch (error) {
    console.error(error)
  }

  if (
    window.tonos_integration_content_id !== undefined &&
    window.tonos_integration_application_id !== undefined
  ) {
    await authBuilderStore.getTonosAccess()
  } else {
    console.log('no tonos integration')
  }

  // tonosHandler.setup(
  //   'app691',
  //   'a7d4815bc45846158139f568ec437640',
  //   'e4045bdf5ab8423a86932a0556b84f9c',
  //   'b75ec1d0bd944f64aef6e09599cd0e15'
  // )

  if (getProjectId.value === 'rWbMwrOxNq2') {
    tonosHandler.init()

    //   await authBuilderStore.getTonosAccess()
  }

  // console.log("time app.vue onMounted middle");
  window.vpappsLogin = authBuilderStore.callLoginForTonos
  window.vpappsLogout = authBuilderStore.callLogOut
  window.vpappsUser = user.value
  window.vpAppsLoginRedirect = authBuilderStore.callLogin

  if (!isEmbed.value || true) {
    try {
      await signalrBuilderStore.createConnection()
    } catch (error) {
      console.error(error)
    }
  }

  console.log('time app.vue onMounted end')
})

onNuxtReady(async () => {
  console.log('time app.vue onMounted start')
  useHandleResize()
})

try {
  // const res = await $fetch("/api/toggle-cache");
  // const addCache = res.cache.control;
  const config = useRuntimeConfig()
  const disableCache = config.public.disableCache

  if (!disableCache) {
    useRouteCache((helper) => {
      helper.setMaxAge(60).setCacheable()
    })
  }
} catch (error) {
  console.error(error)
}
</script>

<template>
  <div
    class="vp-app h-auto min-h-max flex flex-col flex-grow"
    :style="generalStyle"
    :class="
      isEmbed || (!isEmbed && showHeaderFooter) ? null : 'no-header-footer'
    "
  >
    <!-- <pre class="text-white fixed bg-red-500 z-50 bottom-0">
      -------{{ ssrPageData }}
    </pre> -->

    <TheHeader v-if="!isEmbed && showHeaderFooter" :headerData="headerData" />

    <!-- <PageRedirectingToLogin v-if="isRedirectingToLogin && false" /> -->

    <!-- <template v-else> -->
    <!-- here -->
    <NuxtLoadingIndicator
      :height="4"
      :color="'#3b3b3b'"
      class="z-[1111] !opacity-100"
    />
    <NuxtPage :ssrPageData="ssrPageData" :managePageStyle="managePageStyle" />

    <div
      v-if="!isEmbed && showHeaderFooter"
      class="limit-to-load-row h-px bg-transparent z-[1111] relative bottom-0 w-full"
      ref="loadMoreRows"
    ></div>

    <!-- <Notifications /> -->
    <!-- </template> -->

    <TheFooter v-if="!isEmbed && showHeaderFooter" :footerData="footerData" />
  </div>
</template>
