import { defineStore, storeToRefs } from 'pinia'

import AnalyticsProperties from '~~/models/analytics/AnalyticsProperties'
import CustomScript from '../models/script/CustomScript'
import Footer from './../models/footer/Footer'
import Header from './../models/header/Header'
import Layout from '../models/layout/Layout'
import SeoModel from '../models/seo/SeoModel'
import { formatDate } from '~~/utils/formatDate'
import { removeEmbedPrefix } from '~~/utils/removeEmbedPrefix'
import searchLayout from '~~/demoData/searchLayout'
import { useAuthBuilder } from '~/stores/auth-builder'
import { useUrlBuilder } from '~/stores/url-builder'
import EmptyLayout from '~/models/layout/EmptyLayout'

export const usePageBuilder = defineStore({
  id: 'page-builder',
  state: () => {
    return {
      baseUrl: null,
      baseUrlServer: null,
      isLoading: true,
      layoutName: null,
      header: null,
      footer: null,
      slugData: {},
      options: {
        headers: {
          'X-Project-Id': 'bJZEnw5N5BA',
          'X-Project-Domain': 'http://localhost:3000',
        },
      },
      headerss: {},
      windowWidth: null,
      content: null,
      layoutData: null,
      providersData: null,

      //to refactor
      mediaEngagements: {
        likesCount: 0,
        dislikesCount: 0,
        favoritesCount: 0,
        subscribesCount: 0,
        watchLaterCount: 0,
        hasLiked: false,
        hasDisliked: false,
        hasFavorite: false,
        hasSubscribed: false,
        hasWatchLater: false,
      },
      videoDetails: {},
      allRows: null,
      deviceType: null,

      organizationId: null,
      projectDetails: new Map(),
      // relatedVideos: { data: [] },
      errorCode: null,
      videoConfig: {},
      // theaterMode: false,
      // playerConfigs: {
      //   playerId: null,
      //   theaterMode: false,
      //   autoplay: true,
      //   muted: false,
      //   playbackQuality: -1,
      //   volume: 0.5,
      //   playbackRate: 1,
      // },
      serverWaitTime: 4000,
      global: { language: null },

      pageType: 'homepage',
      media: {},
      content: {},
      queries: null,
      inputs: null,
      page: null,
    }
  },
  actions: {
    SET_BASE_URL(data) {
      this.baseUrl = data
    },
    SET_BASE_URL_SERVER(data) {
      this.baseUrlServer = data
    },
    SET_CONFIG(data) {
      this.SET_BASE_URL(data.public.baseUrl)

      this.SET_BASE_URL_SERVER(data.public.baseUrlServer)
    },
    UPDATE_WINDOW_WIDTH(data) {
      this.windowWidth = data
    },
    UPDATE_SLUG_DATA(data) {
      this.slugData = data
    },
    UPDATE_MEDIA_ID_BY_COMPOENENT(id) {
      this.media = {
        id,
      }
    },
    SET_MEDIA_ON_STATE(data) {
      this.media = data
    },
    SET_QUERIES_ON_STATE(data) {
      this.queries = data
    },
    SET_INPUTS_ON_STATE(data) {
      this.inputs = data
    },
    SET_CONTENT_ON_STATE(data) {
      this.content = data
    },
    SET_PAGE_ON_STATE(data) {
      this.page = data
    },
    SET_CUSTOM_SCRIPTS_ON_STATE(data) {
      this.customScripts = data
    },
    UPDATE_PAGE_TYPE(data) {
      this.pageType = data
    },
    setDeviceType(isMobile, isTablet, isDesktop) {
      const urlBuilderStore = useUrlBuilder()
      const { userAgent } = storeToRefs(urlBuilderStore)

      let deviceType = 'Desktop'

      if (isMobile) {
        deviceType = 'mobile'
      } else if (isDesktop) {
        deviceType = 'desktop'
      } else if (isTablet) {
        deviceType = 'tablet'
      }

      this.deviceType = deviceType
    },
    async actionGetAuthProviders() {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await useFetch(`${baseUrl}/project-auth-providers`, {
          headers: getRequestHeaders.value,
        })

        this.providersData = response.data.value.result

        // return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetPageInit(data) {
      const to = data.replace('/embed', '')

      const route = useRoute()

      let queryString = ''

      if (route.query) {
        const params = new URLSearchParams()

        ;['preview', 'version', 'page-id', 'tab'].forEach((param) => {
          if (route.query[param]) {
            params.append(param, route.query[param])
          }
        })

        if (params.toString()) {
          queryString = `&${params.toString()}`
        }
      }

      const baseUrlServer = this.baseUrlServer

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders, xVpAppsDomain, xVpAppsProjectId } =
        storeToRefs(urlBuilderStore)

      // const abortController = new AbortController()
      // let serverTimeout = null
      // const timeout = this.serverWaitTime

      let path = to
      if (to === '/callback') {
        path = '/'
      }

      console.error(
        '------------------------->>init request url',
        `${baseUrlServer}/init${`?url=${
          xVpAppsDomain.value + path
        }${queryString}`}`
      )
      // console.error('init getRequestHeaders', getRequestHeaders.value)

      try {
        const response = await $fetch(
          `${baseUrlServer}/init${`?url=${
            xVpAppsDomain.value + path
          }${queryString}`}`,
          {
            headers: {
              ...getRequestHeaders.value,
              'x-device-type': this.deviceType,
            },
          }
        )

        if (!response) {
          throw 'page init issues'
          return
        }

        const results = response.result

        const { header, footer, page, authProviders, projects } = results

        // this.setPageData(page)
        const urlBuilderStore = useUrlBuilder()
        const { config, isCLH } = storeToRefs(urlBuilderStore)

        const { customScripts } = page ?? {}
        //PAGEBUILDRE SETTING ON STORE SCRIPTS
        const customScriptsSSR = new CustomScript(isCLH.value)
        customScriptsSSR.fromData(customScripts)

        const { getProjectId } = storeToRefs(urlBuilderStore)
        // this.customScripts = customScripts

        // console.error(config.value)

        const customScriptData = customScriptsSSR.getServerSideScripts(
          config.value?.public.analyticsUrl
        )
        // Manage layout
        // const { layout } = page

        //Manage  authProviders
        if (authProviders) {
          this.providersData = authProviders
        }

        //set Layout
        const layout = new Layout(this.deviceType)

        if (page) {
          layout.fromData(page)

          if (page.content) this.UPDATE_PAGE_TYPE('content')

          if (page.media) this.UPDATE_PAGE_TYPE('media')
        } else {
          //set default layout
          layout.fromNoData()
          this.slugData.errorMessage = 'Page not Found'
          this.UPDATE_PAGE_TYPE('error')
        }

        //set projects
        if (projects) {
          const map = projects.reduce((result, item) => {
            result.set(item.id, item)
            return result
          }, new Map())
          this.projectDetails = map
          const language =
            this.projectDetails.get(xVpAppsProjectId.value)?.language ?? 'en'
          // const language = 'en'
          this.global.language = language
        }

        const rootStyle = layout.getRootStyle()

        const headerData = new Header(this.deviceType)

        try {
          headerData.fromDataV2(header)
        } catch (error) {
          console.error('Trying to set header error: ', error)
        }

        const footerData = new Footer(this.deviceType)
        try {
          footerData.fromData(footer.layout)
        } catch (error) {
          console.error('Set Footer error: ', error)
        }

        return {
          scriptsSSR: customScriptData,
          header: headerData,
          footer: footerData,
          rootStyle,
          page,
        }
      } catch (error) {
        throw error
      }
    },

    async actionGetLayoutData(to, useSSR = false, page = null) {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders, xVpAppsDomain } = storeToRefs(urlBuilderStore)

      let layout = new Layout(this.deviceType)
      let customScriptsLoadEachPage
      let customScriptsLoadOnce
      let analitycsData
      let mediaData
      let seoData
      let customProperties

      this.errorCode = null

      try {
        const route = useRoute()

        let queryString = ''

        if (route.query) {
          const params = new URLSearchParams()

          ;['preview', 'version', 'page-id', 'tab'].forEach((param) => {
            if (route.query[param]) {
              params.append(param, route.query[param])
            }
          })

          if (params.toString()) {
            queryString = `?${params.toString()}`
          }
        }

        let response = null
        if (to.includes('/results')) {
          response = { result: searchLayout }
        } else {
          if (useSSR) {
            response = { result: page }
          } else {
            // response = await $fetch(
            //   `${baseUrl}/page${`?url=${xVpAppsDomain.value + to}${queryString}`}`,
            //   { headers: getRequestHeaders.value, }
            // );
            let toUrl = ''

            toUrl = to === '/' ? '/homepage' : to

            toUrl = removeEmbedPrefix(toUrl)

            try {
              response = await $fetch(`/api/page${toUrl}${queryString}`, {
                headers: getRequestHeaders.value,
              })
            } catch (error) {
              response = new EmptyLayout()
              console.error(response)
            }
          }
        }

        const { result } = response

        this.setPageData(result)

        //Manage seoData
        const { seo } = result ?? {}

        //Manage customscripts
        const { customScripts } = result ?? {}

        const urlBuilderStore = useUrlBuilder()
        const { isCLH } = storeToRefs(urlBuilderStore)

        const customScriptsModel = new CustomScript(isCLH.value)
        customScriptsModel.fromData(customScripts)
        customScriptsLoadEachPage =
          customScriptsModel.getCustomScriptsToLoadEachPage()
        customScriptsLoadOnce = customScriptsModel.getCustomScriptsToLoadOnce()

        const seoModel = new SeoModel()
        seoModel.fromData(result)

        seoData = seoModel.getSeoData()
        // const { media } = response ?? {}
        // this.SET_MEDIA_ON_STATE(media)

        // layout.fromData(response.result)
        // this.slugData.errorMessage = null

        analitycsData = this.getAnalyticsData(result, to)

        if (result) {
          this.slugData.errorMessage = null

          const { media, content } = result

          // this.mediaData = media

          mediaData = media

          if (content) {
            customProperties = content.customProperties
          }

          if (media) {
            this.UPDATE_PAGE_TYPE('media')
          } else {
            this.UPDATE_PAGE_TYPE(content ? 'content' : 'homepage')
          }

          layout.fromData(result)
        } else {
          this.slugData.errorMessage = 'Page Not Found'
          this.UPDATE_PAGE_TYPE('error')
        }
        // }
        return {
          rows: layout.layout[this.deviceType].rows || [],
          style: layout.layout[this.deviceType].style,
          customScriptsLoadEachPage: customScriptsLoadEachPage,
          customScriptsLoadOnce: customScriptsLoadOnce,
          analitycsData,
          media: mediaData,
          seo: seoData,
          customProperties: customProperties || null,
        }
      } catch (error) {
        console.error('API errorm', error)
        console.error('API error status code', error.statusCode)
        //if is error
        this.errorCode = 404

        if (error?.statusCode === 404) {
          this.errorCode = 404
          this.UPDATE_PAGE_TYPE('error')
        }

        // layout.fromData({ errorMessage: error, isErrorPage: true })
      } finally {
      }

      return layout
    },
    async actionGetMediaData(data) {
      let mediaId = this.media.id

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      let media = null

      try {
        // const response = data

        if (data && data.model) {
          media = data.model
        } else {
          const response = await $fetch(`${baseUrl}/medias/${`${mediaId}`}`, {
            headers: getRequestHeaders.value,
          })

          media = response.result
        }

        // const mediaResponse = data //response.result

        // this.videoDetails.title = media.title;
        // this.videoDetails.description = media.description;

        // const views = media.viewCount;
        // this.videoDetails.viewCount = views?.toLocaleString();

        // let formattedDate = null;
        // try {
        //   formattedDate = formatDate(media?.startDate);
        // } catch (error) {
        //   console.error(error);
        // }

        // this.videoDetails.startDate = formattedDate;
        // this.videoDetails.length = media?.length;
        // this.videoDetails.imageAssets = media?.imageAssets;
        // // this.UPDATE_PAGE_TYPE('media')

        return media
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetUserEngagement() {
      const mediaId = this.media.id

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/medias/${`${mediaId}`}/engagement-counts`,
          {
            headers: getRequestHeaders.value,
          }
        )

        this.mediaEngagements = response.result

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetMediaViewsCount() {
      const mediaId = this.media.id

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/medias/${`${mediaId}`}/views`,
          {
            headers: getRequestHeaders.value,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async postGetWatchTimeByMediaIds(data) {
      const baseUrl = this.baseUrl

      const body = {
        mediaIds: data,
      }

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(`${baseUrl}/media/user/watchtimes`, {
          method: 'POST',
          headers: getRequestHeaders.value,
          body,
        })

        return response.result || []
      } catch (error) {
        console.error(error)
      }
    },

    async actionPostUserEngagement(
      engagementTypeId,
      entityType = 1,
      entityId = null
    ) {
      let mediaId = null

      if (entityId) {
        mediaId = entityId
      } else {
        mediaId = this.media.id
      }
      const entityTypeId = entityType

      const body = {
        engagementTypeId,
      }

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/entity/${mediaId}/entity-type/${entityTypeId}/engagements`,
          {
            method: 'POST',
            headers: getRequestHeaders.value,
            body,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionDeleteUserEngagement(
      engagementTypeId,
      entityType = 1,
      entityId = null
    ) {
      let mediaId = null

      if (entityId) {
        mediaId = entityId
      } else {
        mediaId = this.media.id
      }

      const entityTypeId = entityType

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/entity/${mediaId}/entity-type/${entityTypeId}/engagements/${engagementTypeId}`,
          {
            method: 'DELETE',
            headers: getRequestHeaders.value,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetMediaRelated(params) {
      const queryParams = new URLSearchParams(params)

      const mediaId = this.media?.id

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      // const url = `${baseUrl}/media/${`${mediaId}/related?${queryParams}`}`

      try {
        const response = await $fetch(
          `${baseUrl}/medias/${`${mediaId}/related?${queryParams}`}`,
          {
            headers: getRequestHeaders.value,
          }
        )

        // this.relatedVideos = response.result;
        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetMediaNewest(projectIds = []) {
      const stringProjectIds = projectIds.join(',')

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/newest-media?projects=${stringProjectIds}`,
          {
            headers: getRequestHeaders.value,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetWatchVideos() {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/media/user/continue-watching`,
          {
            headers: getRequestHeaders.value,
          }
        )

        return response
      } catch (error) {
        // console.error(error)
      }
    },
    async actionGetTonosProductList() {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/user/tonos/access?contentId=${window.tonos_integration_content_id}`,
          {
            headers: {
              ...getRequestHeaders.value,
              appId: window.tonos_integration_application_id,
            },
          }
        )

        return response
      } catch (error) {
        // console.error(error)
      }
    },
    async actionGetMediaComments(params) {
      const queryParams = new URLSearchParams(params)

      const mediaId = this.media?.id

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      // const url = `${baseUrl}/media/${`${mediaId}/related?${queryParams}`}`

      try {
        const response = await $fetch(
          `${baseUrl}/v2/medias/${`${mediaId}/comments?${queryParams}`}`,
          {
            headers: getRequestHeaders.value,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },

    async actionGetCommentReplies(commentId) {
      const mediaId = this.media.id
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/v2/medias/${mediaId}/comments/${commentId}/replies`,
          {
            headers: getRequestHeaders.value,
          }
        )

        return response.result.comments.items
      } catch (error) {
        console.error(error)
      }
    },

    async actionPostComment(text, parentId = null) {
      const mediaId = this.media.id

      const body = {
        text,
        parentId,
      }

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(`${baseUrl}/medias/${mediaId}/comments`, {
          method: 'POST',
          headers: getRequestHeaders.value,
          body,
        })

        return response.result
      } catch (error) {
        console.error(error)
      }
    },

    async actionDeleteComment(commentId) {
      const mediaId = this.media.id

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/medias/${mediaId}/comments/${commentId}`,
          {
            method: 'DELETE',
            headers: getRequestHeaders.value,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionSearchMedia(params, keyword) {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      // const url = `${baseUrl}/media/${`${mediaId}/related?${queryParams}`}`

      try {
        const response = await $fetch(`${baseUrl}/search?q=${keyword}`, {
          headers: getRequestHeaders.value,
        })

        return response.response
      } catch (error) {
        console.error(error)
      }
    },
    async actionSearchMediaPage(params, keyword) {
      const page = params.page
      const groupId = params.groupId

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      // const url = `${baseUrl}/media/${`${mediaId}/related?${queryParams}`}`
      let url = `${baseUrl}/search/page?q=${keyword}`
      if (groupId != null && page != null) {
        url = `${baseUrl}/search/page?q=${keyword}&groupId=${groupId}&page=${page}`
      }

      try {
        const response = await $fetch(url, {
          headers: getRequestHeaders.value,
        })

        return response.response
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetNotifications(params) {
      const queryParams = new URLSearchParams(params)

      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      // const url = `${baseUrl}/media/${`${mediaId}/related?${queryParams}`}`

      try {
        const response = await $fetch(`${baseUrl}/notifications?skip=0`, {
          headers: getRequestHeaders.value,
        })

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionPutReadNotification(notificationId) {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(
          `${baseUrl}/notifications/read/${notificationId}`,
          {
            method: 'POST',
            body: { notificationId },
            headers: getRequestHeaders.value,
          }
        )

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionGetQueryDataInitClient(
      queryId,
      params,
      contentId = null,
      hasAiHub = false,
      rowOrder = null
    ) {
      let response = null

      try {
        response = await this.actionGetQueryDataFromInit(
          queryId,
          params,
          contentId,
          hasAiHub,
          rowOrder
        )
      } catch (error) {
        console.error(error)
      }

      if (!response) {
        // query-server

        if (process.server) {
          return
        }

        try {
          response = await this.actionGetQueryDataFromClient(
            queryId,
            params,
            contentId,
            hasAiHub,
            rowOrder
          )
        } catch (error) {
          console.error(error)
        }
      }

      return { response }
    },
    async actionGetQueryDataFromInit(
      queryId,
      params,
      contentId = null,
      hasAiHub = false
    ) {
      if (hasAiHub) return null

      const cId = contentId || this.inputs.contentId

      if (params && (params?.skip ? params.skip === 0 : true)) {
        const queryFoundOnPage = this.queries.find(
          (query) =>
            query.id === queryId &&
            query.contentId == cId &&
            (!params?.sort || params.sort === query.sort)
        )

        if (queryFoundOnPage) {
          return queryFoundOnPage
        }
      }
    },
    async actionGetQueryDataFromClient(
      queryId,
      params,
      contentId = null,
      hasAiHub = false,
      rowOrder = false
    ) {
      // if (process.server) {
      //   //if server do not make api request
      //   return -1
      // }

      let biskoId = null
      if (typeof biskota != 'undefined' && biskota) {
        biskoId = biskota.getBiskoId()
      }

      let gii = null
      if (contentId) {
        gii = contentId
      } else {
        gii = this.inputs.contentId
      }

      const pageParams = {
        pageContentId: this.content?.id,
      }

      const pageId = this.page.id
      const ssr = process.server

      const queryParams = new URLSearchParams({
        ...(gii ? { contentId: gii } : {}),
        ...params,
        ...(pageParams.pageContentId ? pageParams : {}),
        pageId,
        ...(biskoId ? { biskoId } : {}),
        ...(hasAiHub ? { aiHub: hasAiHub } : {}),
        ...(ssr ? { ssr: ssr } : {}),
        ...(rowOrder ? { row: rowOrder } : {}),
      })

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      // let requestBaseUrl = 'https://yarp.vpapps.gjirafa.tech'
      let requestBaseUrl = this.baseUrl

      if (!queryId) {
        throw 'No queryId provided.'
      }

      try {
        const response = await $fetch(
          `${requestBaseUrl}/query/${`${queryId}?${queryParams}`}`,
          {
            headers: getRequestHeaders.value,
          }
        )

        // return response.result
        // if (response.data.value) return response.data.value.result;
        if (response.result) return response.result
      } catch (error) {
        console.error(error)
      }
    },

    async actionGetCheckoutDetails(id) {
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(`${baseUrl}/checkout/${id}`, {
          headers: getRequestHeaders.value,
        })

        return response.result
      } catch (error) {
        console.error(error)
      }
    },
    async actionPutCheckoutDetails(data) {
      const body = {
        checkoutDetails: {
          ...data,
        },
      }
      const baseUrl = this.baseUrl

      const urlBuilderStore = useUrlBuilder()
      const { getRequestHeaders } = storeToRefs(urlBuilderStore)

      try {
        const response = await $fetch(`${baseUrl}/checkout`, {
          method: 'POST',
          headers: getRequestHeaders.value,
          body,
        })

        return response.result
      } catch (error) {
        console.error(error)
      }
    },

    setPageData(page) {
      //manage queries
      const { queries } = page ?? []
      this.SET_QUERIES_ON_STATE(queries)

      const { inputs } = page ?? {}
      this.SET_INPUTS_ON_STATE(inputs)

      //!!TODO: manage seo data per page
      const { seo } = page ?? {}

      // Manage media
      const { media } = page ?? {}
      this.SET_MEDIA_ON_STATE(media)
      // Manage content
      const { content } = page ?? {}
      this.SET_CONTENT_ON_STATE(content)

      //Manage page
      const { id } = page ?? {}
      this.SET_PAGE_ON_STATE({ id })

      // //Manage customScripts
      // const { customScripts } = page ?? {}
      // this.SET_CUSTOM_SCRIPTS_ON_STATE(customScripts)
    },
    getSeoData(data) {},
    getAnalyticsData(data, path) {
      const projectDetails = this.projectDetails
      const { user, media, content, analyticsProperties } = data ?? {}

      // return analyticsProperties

      const urlBuilderStore = useUrlBuilder()
      const authBuilderStore = useAuthBuilder()

      const { getProjectId, token } = storeToRefs(urlBuilderStore)
      const { userToken } = storeToRefs(authBuilderStore)
      // const route = useRoute();

      const analyticsData = new AnalyticsProperties()

      let organizationId = null
      if (projectDetails && projectDetails.get(getProjectId.value)) {
        organizationId = projectDetails.get(getProjectId.value).organizationId
      }

      // if (!layout) return null;

      analyticsData.page.setPageProperties(data)

      if (content) {
        analyticsData.setContentId(content.id)
      } else if (media) {
        analyticsData.setMediaId(media.id)

        if (media.model) {
          const title = media.model.title
          const length = media.model.length
          const type = media.model.typeId
          const id = media.model.mediaId
          const tags = media.model.tags
          const lockedAfter = media.model.lockedAfter
          // const category = layout.media.model.category

          analyticsData.setParentDataToMediaTags(media.model.parents)
          analyticsData.setMediaData({
            title,
            length,
            type,
            id,
            tags,
            lockedAfter,
          })
        }
      }

      if (user) {
        analyticsData.setUid(user)
      }

      // if (layout.user) {
      //   analyticsData.setUser(layout.user?.id);
      // }

      // console.error('userToken.value', userToken.value, token.value)

      analyticsData.setUserToken(token.value || userToken.value)

      analyticsData.setExtraAnalyticsProperties(analyticsProperties)

      analyticsData.setIsEmbed(path)

      analyticsData.setProjectId(getProjectId.value)
      analyticsData.setOrganizationId(organizationId)
      analyticsData.setLoginStatus(!!userToken.value ? 'loggedin' : 'anonymous')
      analyticsData.setUserId(user?.value?.sid)
      analyticsData.setDeviceType(this.deviceType)
      // analyticsData.setErrorType(state.errorCode);

      return analyticsData
    },
  },
  getters: {
    getErrorMessage(state) {
      return state.slugData.errorMessage
    },
    getProjectDetails(state) {
      return state.projectDetails
    },
    getCurrentProjectDetails(state) {
      const urlBuilderStore = useUrlBuilder()
      const { getProjectId } = storeToRefs(urlBuilderStore)

      return state.projectDetails.get(getProjectId.value)
    },
    getPalette(state) {
      const urlBuilderStore = useUrlBuilder()
      const { config, getProjectId } = storeToRefs(urlBuilderStore)

      const cdnBaseUrl = config.value.public.cdnBaseUrl

      if (state.getCurrentProjectDetails?.useSystemPalette) {
        return false
      }

      return `${cdnBaseUrl}/palettes/${getProjectId.value}/palette.css`
    },

    getFontPack(state) {
      const urlBuilderStore = useUrlBuilder()
      const { config, getProjectId } = storeToRefs(urlBuilderStore)

      const cdnBaseUrl = config.value.public.cdnBaseUrl

      if (state.getCurrentProjectDetails?.useCustomFont) {
        return `${cdnBaseUrl}/fonts/${getProjectId.value}/fonts.css`
      }
    },
    getIconPack(state) {
      const urlBuilderStore = useUrlBuilder()
      const { config, getProjectId } = storeToRefs(urlBuilderStore)

      const cdnBaseUrl = config.value.public.cdnBaseUrl

      if (
        !state.getCurrentProjectDetails ||
        state.getCurrentProjectDetails?.useSystemIconPack
      ) {
        return `${cdnBaseUrl}/fonts/default/vpapps-font.css`
      }
      return `${cdnBaseUrl}/fonts/${getProjectId.value}/vpapps-font.css`
    },
    getProjectFavIcon(state) {
      const urlBuilderStore = useUrlBuilder()
      const { getProjectId } = storeToRefs(urlBuilderStore)

      if (!state.projectDetails) return

      const currentProject = state.projectDetails.get(getProjectId?.value)

      if (!currentProject) return

      return currentProject.favIcon || 'https://vpapps.gjirafa.dev/favicon.ico'
    },
    getLayoutMediaData(state) {
      return state.media?.model
    },
    getVideoStructuredData(state) {
      return {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        ...state.layoutData?.structuredData,
      }
    },
  },
})
