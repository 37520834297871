<script setup>
import { JHeaderProfileV2 } from "media-flow-ui"

import { usePageBuilder } from "~/stores/page-builder"
import { useAuthBuilder } from "~/stores/auth-builder"

import { storeToRefs } from "pinia"
import CookieStorage from "~/services/CookieStorage"
import { useUrlBuilder } from "~/stores/url-builder"
import { formatProfileStyle } from "@/helpers/header/profile"
import { $$t } from "~~/utils/i19m"
import { formatIconStyle } from "~/helpers/icon/icon"
const urlBuilder = useUrlBuilder()
const pageBuilderStore = usePageBuilder()
const authBuilderStore = useAuthBuilder()
const { deviceType } = storeToRefs(pageBuilderStore)
const { getProjectId } = storeToRefs(urlBuilder)
const { isLogedInSSR, userToken, userSubId } = storeToRefs(authBuilderStore)

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  onToggle: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
})

const { user } = storeToRefs(authBuilderStore)
const localData = ref({ ...props.data })
const login = async () => {
  try {
    await authBuilderStore.callLogin()
  } catch (error) {
    console.error("Login click", error)
  }
}

const logout = async () => {
  const cookie = new CookieStorage()
  cookie.removeItem("mAccessToken")
  isLogedInSSR.value = false
  userToken.value = null
  userSubId.value = null
  authBuilderStore.unsetUserOnCookie()

  await authBuilderStore.callLogOut()
}

const formattedData = computed(() => ({
  ...localData.value,
  style: formatProfileStyle(localData.value.style),
}))

onNuxtReady(() => {
  if (getProjectId.value === "rWbMwrOxNq2" && deviceType.value === "desktop") {
    if (userToken.value) {
      localData.value.props.type = "text"
      localData.value.props.text = "Log out"
      localData.value.style.fontProperties.color = "#B70000"
    } else {
      localData.value.props.type = "text"
    }
  }
})

const iconsStyle = formatIconStyle(props.data.icon)
</script>

<template>
  <!-- <pre>{{ props.data.style.fontProperties.color }}</pre> -->
  <JHeaderProfileV2
    :class="'order-' + data.order"
    class="items-center justify-center flex"
    :is-open="isOpen"
    :on-toggle="onToggle"
    :on-close="onClose"
    :breakpoint="breakpoint"
    :data="formattedData"
    :userProfileText="$$t('component_header_profile_user_profile')"
    :user-data="{
      accountName: user?.name,
      name: user?.name,
      surname: user?.surname,
      accountPicture: user?.picture,
      accountProfileUrl:
        pageBuilderStore?.providersData?.issuer ||
        'https://myaccount.fameplay.tv/',
    }"
    :icons="iconsStyle"
    :login-callback="login"
    :logout-callback="logout"
    :log-out-text="$$t('component_header_profile_log_out')"
  />
</template>

<style></style>
