import validate from "/webapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/webapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-check": () => import("/webapp/middleware/authCheck.js"),
  "require-plan": () => import("/webapp/middleware/requirePlan.js"),
  slug: () => import("/webapp/middleware/slug.js")
}